import { Popover } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlexMutation, useFlexQuery } from 'hooks';
import { isEmpty, isEqual, isNull, isUndefined, map } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import * as yup from 'yup';
import { mainClient } from '../../../../apollo/client';
import {
  CREATE_SAVED_ITEM,
  CreateSavedItemData,
  CreateSavedItemVariables,
  SavedItemType,
} from '../../../../graphql/main/mutations/create-saved-item';
import {
  CREATE_USER_COLLECTION,
  CreateUserCollectionData,
  CreateUserCollectionVariables,
} from '../../../../graphql/main/mutations/create-user-collection';
import {
  DELETE_SAVED_ITEM,
  DeleteSavedItemData,
  DeleteSavedItemVariables,
} from '../../../../graphql/main/mutations/delete-saved-item';
import {
  UPDATE_SAVED_ITEM,
  UpdateSavedItemData,
  UpdateSavedItemVariables,
} from '../../../../graphql/main/mutations/update-saved-item';
import { SavedItem } from '../../../../graphql/main/queries/get-real-estates';
import {
  Collection,
  CollectionEnum,
  GET_USER_COLLECTIONS_BY_TYPE,
  GetUserCollectionsByTypeData,
  GetUserCollectionsByTypeVariables,
} from '../../../../graphql/main/queries/get-user-collections-by-type';
import FormTextInput from '../../../shared/form-text-input';

const schema = yup.object().shape({
  search: yup.string(),
  selectCollection: yup.string(),
  inputAddCollection: yup.string().required('Vui lòng nhập tên bộ sưu tập'),
});

interface Props {
  realEstateId?: string;
  realEstateType?: string;
  isSaved?: boolean;
  saveItem?: SavedItem;
  onClose?: Function;
  onCompleted?: (type: 'add' | 'update' | 'delete') => void;
}

const SavePostPopover = ({
  realEstateId,
  realEstateType,
  isSaved,
  saveItem,
  onClose,
  onCompleted,
}: Props) => {
  const [isShowingAddCollection, setIsShowingAddCollection] = useState(false);
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      search: '',
      selectCollection: saveItem?.belongsToCollection?.id || '',
      inputAddCollection: '',
    },
  });
  const searchWatch = useWatch({ control: form.control, name: 'search' });
  const selectCollectionWatch = useWatch({ control: form.control, name: 'selectCollection' });
  const inputAddCollectionWatch = useWatch({
    control: form.control,
    name: 'inputAddCollection',
  });
  const collectionType = isEqual(realEstateType, 'RealEstate')
    ? CollectionEnum.estate
    : isEqual(realEstateType, 'NeededRealEstate')
    ? CollectionEnum.need_estate
    : CollectionEnum.estate;
  const {
    data: getUserCollectionsData,
    loading: isLoadingGetUserCollections,
    refetch: refetchGetUserCollections,
  } = useFlexQuery<GetUserCollectionsByTypeData, GetUserCollectionsByTypeVariables>(
    'main',
    GET_USER_COLLECTIONS_BY_TYPE,
    {
      variables: {
        type: collectionType,
        realEstateId,
      },
    },
  );
  const { getUserCollectionsByType: collections } = getUserCollectionsData || {};
  const [createCollection, { error: createCollectionError }] = useFlexMutation<
    CreateUserCollectionData,
    CreateUserCollectionVariables
  >('main', CREATE_USER_COLLECTION);
  const [createSavedItem] = useFlexMutation<CreateSavedItemData, CreateSavedItemVariables>(
    'main',
    CREATE_SAVED_ITEM,
  );
  const [updateSavedItem] = useFlexMutation<UpdateSavedItemData, UpdateSavedItemVariables>(
    'main',
    UPDATE_SAVED_ITEM,
  );
  const [deleteSavedItem] = useFlexMutation<DeleteSavedItemData, DeleteSavedItemVariables>(
    'main',
    DELETE_SAVED_ITEM,
  );

  const handleOnClose = () => {
    onClose?.();
  };
  const handleOnToggleAddCollection = () => {
    form.reset();
    setIsShowingAddCollection(!isShowingAddCollection);
  };
  const handleOnSearch = (key: string) => {
    refetchGetUserCollections({
      type: collectionType,
      name: key,
    });
  };
  const handleOnSelectCollection = (collection: Collection) => {
    if (isShowingAddCollection) handleOnToggleAddCollection();
    form.setValue('selectCollection', collection.id || '');
  };
  const handleOnCreateCollection = (name: string, realEstateId: string | undefined) => {
    createCollection({
      variables: {
        type: collectionType,
        name,
        itemId: realEstateId,
      },
      onCompleted: (data) => {
        if (createCollectionError)
          form.setError('inputAddCollection', { message: createCollectionError.message });
        if (!isNull(data.createUserCollection)) {
          mainClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getRealEstates' });
          mainClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getRealEstateDetails' });
          onCompleted?.('add');
          onClose?.();
        }
      },
    });
  };
  const handleOnCreateSavedItem = (
    realEstateId: string | undefined,
    collectionId: string | undefined,
  ) => {
    if (!isUndefined(realEstateId) && !isUndefined(collectionId))
      createSavedItem({
        variables: {
          type: SavedItemType[collectionType],
          belongsToCollection: collectionId,
          item: realEstateId,
        },
        onCompleted: () => {
          onCompleted?.('add');
          onClose?.();
        },
      });
  };
  const handleOnUpdateSavedItem = (
    savedItemId: string | undefined,
    collectionId: string | undefined,
  ) => {
    if (!isUndefined(savedItemId))
      updateSavedItem({
        variables: {
          savedItemId,
          belongsToCollection: collectionId,
        },
        onCompleted: () => {
          onCompleted?.('update');
          onClose?.();
        },
      });
  };
  const handleOnDeleteSavedItem = (savedItemId: string | undefined) => {
    if (!isUndefined(savedItemId))
      deleteSavedItem({
        variables: {
          savedItemId,
        },
        onCompleted: () => {
          onCompleted?.('delete');
          onClose?.();
        },
      });
  };
  const onSubmit = () => {
    handleOnCreateCollection(inputAddCollectionWatch, realEstateId);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleOnSearch(searchWatch);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchWatch]);

  return (
    <Popover.Panel
      id='save-post-popover'
      className='absolute right-full top-1/2 w-[340px] min-w-[340px] -translate-y-1/2 -translate-x-[20px] rounded-[8px] bg-paper shadow-4'
    >
      <div className='flex flex-col space-y-[24px] py-[16px]'>
        <span className='select-none px-[16px] font-[600]'>Chọn bộ sưu tập để lưu</span>
        <FormProvider {...form}>
          <form
            id='collection-creation-form'
            autoComplete='off'
            className='flex flex-col space-y-[24px]'
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className='px-[16px]'>
              <FormTextInput
                name='search'
                label='Tìm kiếm bộ sưu tập'
                wrapperClassName='h-[40px] p-[8px] w-full'
                endIcon={
                  isLoadingGetUserCollections ? (
                    <AiOutlineLoading3Quarters className='min-h-[18px] min-w-[18px] animate-spin select-none text-primary' />
                  ) : (
                    <IoSearchOutline
                      className='min-h-[18px] min-w-[18px] cursor-pointer'
                      onClick={() => handleOnSearch(form.getValues('search'))}
                    />
                  )
                }
              />
            </div>
            {!isEmpty(collections?.items) ? (
              <ul
                id='collection-list'
                className='mt-[20px] max-h-[168px] space-y-[12px] overflow-y-auto'
              >
                {map(collections?.items, (collection, collectionIndex) => (
                  <li key={collectionIndex}>
                    <button
                      type='button'
                      className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                      onClick={() => {
                        handleOnSelectCollection(collection);
                      }}
                    >
                      <div
                        className={`min-h-[20px] min-w-[20px] rounded-full border ${
                          isEqual(selectCollectionWatch, collection.id)
                            ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                            : 'border-stroke'
                        }`}
                      />
                      <span className='line-clamp-1'>
                        {`${collection.name} (${collection.totalItems})`}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <span className='mt-[10px] px-[16px] italic text-text2'>
                Không tìm thấy bộ sưu tập
              </span>
            )}
            <div className='px-[16px]'>
              {isShowingAddCollection ? (
                <FormTextInput
                  required
                  name='inputAddCollection'
                  maxLength={118}
                  error={form.formState.errors['inputAddCollection']}
                  label='Bộ sưu tập'
                  wrapperClassName='h-[40px] p-[8px] w-full'
                  onChange={() => {
                    if (!isEmpty(form.getValues('inputAddCollection')))
                      form.setValue('selectCollection', '');
                  }}
                />
              ) : (
                <button
                  type='button'
                  className='flex h-[40px] w-full items-center justify-center space-x-[10px] rounded-[8px] border border-dashed border-primary duration-300 hover:bg-secondary'
                  onClick={handleOnToggleAddCollection}
                >
                  <BiPlus className='min-h-[24px] min-w-[24px] text-primary' />
                  <span className='font-[600] text-primary'>Tạo mới bộ sưu tập</span>
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <div className='h-[1px] bg-stroke' />
      <div className='flex space-x-[12px] px-[16px] pt-[8px] pb-[16px]'>
        {isShowingAddCollection ? (
          <Fragment>
            <button
              type='button'
              className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] border border-text2 bg-background bg-paper py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-tertiary'
              onClick={handleOnToggleAddCollection}
            >
              <span className='font-[600]'>Hủy tạo</span>
            </button>
            <button
              form='collection-creation-form'
              type='submit'
              className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
              onClick={form.handleSubmit(onSubmit)}
            >
              <span className='font-[600]'>Tạo bộ sưu tập</span>
            </button>
          </Fragment>
        ) : isSaved ? (
          <Fragment>
            <button
              type='button'
              className='flex h-[40px] w-full items-center justify-center space-x-[15px] rounded-[8px] border border-text2 bg-background bg-paper py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-tertiary'
              onClick={() => handleOnDeleteSavedItem(saveItem?.id)}
            >
              <span className='font-[600]'>Hủy lưu</span>
            </button>
            <button
              type='button'
              className={`flex h-[40px] w-full items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] px-[28px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light`}
              onClick={() => handleOnUpdateSavedItem(saveItem?.id, selectCollectionWatch)}
            >
              <span className='font-[600]'>Cập nhật</span>
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <button
              type='button'
              className='flex h-[40px] w-full items-center justify-center space-x-[15px] rounded-[8px] border border-text2 bg-background bg-paper py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-tertiary'
              onClick={handleOnClose}
            >
              <span className='font-[600]'>Trở về</span>
            </button>
            <button
              type='button'
              className={`flex h-[40px] w-full items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] px-[28px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light disabled:opacity-50`}
              onClick={() => {
                handleOnCreateSavedItem(realEstateId, selectCollectionWatch);
              }}
              disabled={isEmpty(selectCollectionWatch)}
            >
              <span className='font-[600]'>Xác nhận</span>
            </button>
          </Fragment>
        )}
      </div>
    </Popover.Panel>
  );
};

export default SavePostPopover;
