import { gql } from '@apollo/client';
import { Boolean, ID, Int, ObjectID, String } from '../queries/get-real-estates';
import { CollectionEnum } from '../queries/get-user-collections-by-type';

export interface Collection {
  id?: ID;
  type?: String;
  name?: String;
  owner?: ObjectID;
  totalItems?: Int;
}

export interface CreateUserCollectionData {
  createUserCollection?: {
    success?: Boolean;
    msg?: String;
    payload?: Collection;
  };
}

export interface CreateUserCollectionVariables {
  name: String;
  type: CollectionEnum;
  itemId?: ObjectID;
  note?: String;
}

export const CREATE_USER_COLLECTION = gql`
  mutation createUserCollection(
    $name: String!
    $type: CollectionEnum!
    $itemId: ObjectID
    $note: String
  ) @api(name: realEstate) {
    createUserCollection(name: $name, type: $type, itemId: $itemId, note: $note) {
      success
      msg
      payload {
        id
        type
        name
        owner
        totalItems
      }
    }
  }
`;
