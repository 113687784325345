import { gql } from '@apollo/client';
import { Boolean, ID, Int, ObjectID, String } from './get-real-estates';

export enum CollectionEnum {
  estate = 'estate',
  need_estate = 'need_estate',
  premium = 'premium',
  project = 'project',
  news = 'news',
  search_filter = 'search_filter',
  construction = 'construction',
  law = 'law',
  supplier = 'supplier',
}

export interface Collection {
  id?: ID;
  type?: String;
  name?: String;
  owner?: ObjectID;
  totalItems?: Int;
}
export interface ListCollection {
  total?: Int;
  items?: Collection[];
  limit?: Int;
  page?: Int;
  nextPage?: Int;
  prevPage?: Int;
  totalPages?: Int;
  pagingCounter?: Int;
  hasNextPage?: Boolean;
  hasPrevPage?: Boolean;
}

export interface GetUserCollectionsByTypeData {
  getUserCollectionsByType?: ListCollection;
}

export interface GetUserCollectionsByTypeVariables {
  type: CollectionEnum;
  realEstateId?: String;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_USER_COLLECTIONS_BY_TYPE = gql`
  query getUserCollectionsByType(
    $type: CollectionEnum!
    $realEstateId: String
    $name: String
    $limit: Int
    $page: Int
  ) @api(name: realEstate) {
    getUserCollectionsByType(
      type: $type
      realEstateId: $realEstateId
      name: $name
      limit: $limit
      page: $page
    ) {
      total
      items {
        id
        type
        name
        owner
        totalItems
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
