import { gql } from '@apollo/client';
import { Boolean, ID, SavedItem, String } from '../queries/get-real-estates';

export interface DeleteSavedItemData {
  success?: Boolean;
  msg?: String;
  payload?: SavedItem;
}

export interface DeleteSavedItemVariables {
  savedItemId: ID;
}

export const DELETE_SAVED_ITEM = gql`
  mutation deleteSavedItem($savedItemId: ID!) @api(name: realEstate) {
    deleteSavedItem(savedItemId: $savedItemId) {
      success
      msg
    }
  }
`;
