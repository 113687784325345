import { ToastModel } from 'hooks';
import { isEqual } from 'lodash';
import { IoCheckmarkCircle, IoCloseCircle, IoInformationCircle, IoWarning } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';

interface Props {
  toast?: ToastModel;
}

const Toast = ({ toast }: Props) => {
  const isTypeError = isEqual(toast?.type, 'error');
  const isTypeWarning = isEqual(toast?.type, 'warning');
  const isTypeInformation = isEqual(toast?.type, 'information');

  return (
    <div className='flex overflow-hidden rounded-[4px] drop-shadow-2'>
      <div
        className={`min-w-[4px] ${
          isTypeError
            ? 'bg-error1'
            : isTypeWarning
            ? 'bg-warning1'
            : isTypeInformation
            ? 'bg-information1'
            : 'bg-success1'
        }`}
      />
      <div
        className={`group flex max-w-[394px] items-center space-x-[12px] px-[12px] py-[16px] ${
          isTypeError
            ? 'bg-error2'
            : isTypeWarning
            ? 'bg-warning2'
            : isTypeInformation
            ? 'bg-information2'
            : 'bg-success2'
        }`}
      >
        {isTypeError ? (
          <IoCloseCircle className='min-h-[24px] min-w-[24px] text-error1' />
        ) : isTypeWarning ? (
          <IoWarning className='min-h-[24px] min-w-[24px] text-warning1' />
        ) : isTypeInformation ? (
          <IoInformationCircle className='min-h-[24px] min-w-[24px] text-information1' />
        ) : (
          <IoCheckmarkCircle className='min-h-[24px] min-w-[24px] text-success1' />
        )}
        <div className='flex flex-col space-y-[4px]'>
          <span className='font-[600] text-[#222222] line-clamp-[1]'>
            {isTypeError
              ? 'Lỗi'
              : isTypeWarning
              ? 'Cảnh báo'
              : isTypeInformation
              ? 'Thông tin'
              : 'Thành công'}
          </span>
          <span className='text-[#474747] line-clamp-[3]'>{toast?.content}</span>
        </div>
        <div className='min-h-[20px] min-w-[20px]'>
          <button type='button' className='hidden group-hover:block'>
            <MdClear className='min-h-[20px] min-w-[20px] text-placeholder ' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
