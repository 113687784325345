import { ToastModel } from 'hooks';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Toast from './toast';

interface Props {
  toasts?: ToastModel[];
}

const ToastStack = ({ toasts }: Props) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser
    ? createPortal(
        <div className='fixed right-[10px] bottom-[10px] z-[10000] flex flex-col items-end space-y-[10px]'>
          {map(toasts, (toast, toastIndex) => (
            <Toast key={toastIndex} toast={toast} />
          ))}
        </div>,
        document.querySelector('#toast-stack')!,
      )
    : null;
};

export default ToastStack;
