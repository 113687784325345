import { gql } from '@apollo/client';
import { ID, SavedItem } from '../queries/get-real-estates';

export interface UpdateSavedItemData {
  success?: Boolean;
  msg?: String;
  payload?: SavedItem;
}

export interface UpdateSavedItemVariables {
  savedItemId: ID;
  belongsToCollection?: ID;
  note?: String;
}

export const UPDATE_SAVED_ITEM = gql`
  mutation updateSavedItem($savedItemId: ID!, $belongsToCollection: ID, $note: String)
  @api(name: realEstate) {
    updateSavedItem(
      savedItemId: $savedItemId
      belongsToCollection: $belongsToCollection
      note: $note
    ) {
      success
      msg
      payload {
        id
        belongsToCollection {
          id
          name
        }
      }
    }
  }
`;
